.PageTitle {
    border: 1px solid #ccc;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.782);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.289);

    h1 {
        margin-bottom: 10px;
        font-weight: bold;
    }
}