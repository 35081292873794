.App {
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: rgba(255, 0, 0, 0);
}

canvas {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:-1;
}

