.Home {
    .NavItem {
        height: 100%;
        margin: 0 10px;
        padding: 0px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.898);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
        transition: all 0.3s ease-in-out;

        .Card {
            height: 100%;
            background-color: rgba(242, 242, 242, 0.701);

            &:hover {
                background-color: rgb(255, 254, 238);
            }
        }

        box-shadow: 0 0 10px 0 rgba(63, 63, 63, 0.7);

        &:hover {
            background-color: rgb(227, 227, 227);
        }
    }

    a {
        text-decoration: none;
        text-shadow: rgba(121, 121, 121, 0.652) 3px 3px 5px;
    }
}